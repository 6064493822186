var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2", pageTitle: "Edit Application" } },
    [
      _c(
        "vx-card",
        [
          _c(
            "vs-tabs",
            { staticClass: "tabs-shadow-none" },
            [
              _c(
                "vs-tab",
                {
                  staticClass: "fixed-height-app-edit",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-settings",
                    label: "General Info",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap items-center mb-base mt-6" },
                    [
                      _c("vs-avatar", {
                        staticClass: "mr-4 mb-4 company-logo-avatar",
                        attrs: {
                          src: _vm.form.logoUrl,
                          icon: "cloud_upload",
                          size: "70px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.companyLogoInput.click()
                          },
                        },
                      }),
                      _c(
                        "div",
                        [
                          _c("input", {
                            ref: "companyLogoInput",
                            attrs: {
                              type: "file",
                              hidden: "",
                              id: "companyLogoInput",
                            },
                            on: { change: _vm.handleFileUpload },
                          }),
                          _c(
                            "vs-button",
                            {
                              staticClass: "mr-4 sm:mb-0 mb-2",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.companyLogoInput.click()
                                },
                              },
                            },
                            [_vm._v("Upload App Logo")]
                          ),
                          _c(
                            "vs-button",
                            {
                              attrs: { type: "border", color: "danger" },
                              on: { click: _vm.removeLogo },
                            },
                            [_vm._v("Remove")]
                          ),
                          _c("p", { staticClass: "text-sm mt-2" }, [
                            _vm._v(
                              "Allowed JPG, GIF or PNG. Max size of 800kB"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:50",
                        expression: "'max:50'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: {
                      label: "Application Name*",
                      name: "applicationName",
                    },
                    model: {
                      value: _vm.form.applicationName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "applicationName", $$v)
                      },
                      expression: "form.applicationName",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("applicationName"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Web Platform"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          value: _vm.selectedWebPlatform,
                          "append-to-body": "",
                          options: _vm.webPlatformOptions,
                        },
                        on: { input: _vm.setSelectedWebPlatform },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Subscription*"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          value: _vm.selectedSubscription,
                          "append-to-body": "",
                          options: _vm.subscriptionOptions,
                        },
                        on: { input: _vm.setSelectedSubscription },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex items-center mt-4" },
                    [
                      _c("vs-switch", {
                        model: {
                          value: _vm.form.isDemo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isDemo", $$v)
                          },
                          expression: "form.isDemo",
                        },
                      }),
                      _c("span", { staticClass: "ml-4" }, [_vm._v("Is Demo")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex items-center mt-4" },
                    [
                      _c("vs-switch", {
                        model: {
                          value: _vm.form.isActive,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isActive", $$v)
                          },
                          expression: "form.isActive",
                        },
                      }),
                      _c("span", { staticClass: "ml-4" }, [
                        _vm._v("Is Active"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  staticClass: "fixed-height-app-edit",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-map-pin",
                    label: "Location",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Region*"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          value: _vm.selectedRegion,
                          "append-to-body": "",
                          options: _vm.regionOptions,
                        },
                        on: { input: _vm.setSelectedRegion },
                      }),
                    ],
                    1
                  ),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:250",
                        expression: "'max:250'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: { label: "Address 1", name: "address1" },
                    model: {
                      value: _vm.form.address1,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address1", $$v)
                      },
                      expression: "form.address1",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("address1"))),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:250",
                        expression: "'max:250'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: { label: "Address 2", name: "address2" },
                    model: {
                      value: _vm.form.address2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address2", $$v)
                      },
                      expression: "form.address2",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("address2"))),
                  ]),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/2" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:50",
                              expression: "'max:50'",
                            },
                          ],
                          staticClass: "w-full mt-4",
                          attrs: { label: "City", name: "city" },
                          model: {
                            value: _vm.form.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "city", $$v)
                            },
                            expression: "form.city",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("city"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/2" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:50",
                              expression: "'max:50'",
                            },
                          ],
                          staticClass: "w-full mt-4",
                          attrs: { label: "State", name: "state" },
                          model: {
                            value: _vm.form.state,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "state", $$v)
                            },
                            expression: "form.state",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("state"))),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Country"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          value: _vm.selectedCountry,
                          "append-to-body": "",
                          options: _vm.countryOptions,
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        on: { input: _vm.setSelectedCountry },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  staticClass: "fixed-height-app-edit",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-calendar",
                    label: "Booking",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:50",
                            expression: "'max:50'",
                          },
                        ],
                        staticClass: "w-full mt-4",
                        attrs: {
                          label: "Booking Email Name",
                          name: "bookingEmailName",
                        },
                        model: {
                          value: _vm.form.bookingEmailName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bookingEmailName", $$v)
                          },
                          expression: "form.bookingEmailName",
                        },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("bookingEmailName"))),
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:100|email",
                            expression: "'max:100|email'",
                          },
                        ],
                        staticClass: "w-full mt-4",
                        attrs: { label: "Booking Email", name: "bookingEmail" },
                        model: {
                          value: _vm.form.bookingEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bookingEmail", $$v)
                          },
                          expression: "form.bookingEmail",
                        },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("bookingEmail"))),
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:150",
                            expression: "'max:150'",
                          },
                        ],
                        staticClass: "w-full mt-4",
                        attrs: {
                          label: "Booking Telephone Name",
                          name: "bookingTelephoneName",
                        },
                        model: {
                          value: _vm.form.bookingTelephoneName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bookingTelephoneName", $$v)
                          },
                          expression: "form.bookingTelephoneName",
                        },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(
                          _vm._s(_vm.errors.first("bookingTelephoneName"))
                        ),
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:20",
                            expression: "'max:20'",
                          },
                        ],
                        staticClass: "w-full mt-4 mb-8",
                        attrs: {
                          label: "Booking Telephone",
                          name: "bookingTelephone",
                        },
                        model: {
                          value: _vm.form.bookingTelephone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bookingTelephone", $$v)
                          },
                          expression: "form.bookingTelephone",
                        },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("bookingTelephone"))),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "vs-tab",
                {
                  staticClass: "fixed-height-app-edit",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-info",
                    label: "Support",
                  },
                },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:50",
                        expression: "'max:50'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: {
                      label: "Support Email Name",
                      name: "supportEmailName",
                    },
                    model: {
                      value: _vm.form.supportEmailName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supportEmailName", $$v)
                      },
                      expression: "form.supportEmailName",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("supportEmailName"))),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:100|email",
                        expression: "'max:100|email'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: { label: "Support Email", name: "supportEmail" },
                    model: {
                      value: _vm.form.supportEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supportEmail", $$v)
                      },
                      expression: "form.supportEmail",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("supportEmail"))),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:150",
                        expression: "'max:150'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: {
                      label: "Support Telephone Name",
                      name: "supportTelephoneName",
                    },
                    model: {
                      value: _vm.form.supportTelephoneName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supportTelephoneName", $$v)
                      },
                      expression: "form.supportTelephoneName",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("supportTelephoneName"))),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:20",
                        expression: "'max:20'",
                      },
                    ],
                    staticClass: "w-full mt-4",
                    attrs: {
                      label: "Support Telephone",
                      name: "supportTelephone",
                    },
                    model: {
                      value: _vm.form.supportTelephone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supportTelephone", $$v)
                      },
                      expression: "form.supportTelephone",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("supportTelephone"))),
                  ]),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  staticClass: "fixed-height-app-edit",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-code",
                    label: "API",
                  },
                },
                [
                  _c("div", { staticClass: "vx-row mt-4" }, [
                    _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                      _c("span", [_vm._v("Allow Public API Key")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col sm:w-3/4 w-full" },
                      [
                        _c("vs-switch", {
                          model: {
                            value: _vm.form.allowPublicApiKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "allowPublicApiKey", $$v)
                            },
                            expression: "form.allowPublicApiKey",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.allowPublicApiKey
                    ? _c("div", { staticClass: "vx-row mt-4" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                          _c("span", [_vm._v("Public API Key")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-3/4 w-full" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: { name: "Public API Key", disabled: true },
                              model: {
                                value: _vm.form.publicApiKey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "publicApiKey", $$v)
                                },
                                expression: "form.publicApiKey",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("Public API Key"))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.allowPublicApiKey
                    ? _c(
                        "div",
                        { staticClass: "flex flex-wrap justify-end" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mt-4 mr-4",
                              attrs: {
                                type: "border",
                                color: "danger",
                                "icon-pack": "feather",
                                icon: "icon-refresh-cw",
                                title: "Regenerate public api key",
                              },
                              on: { click: _vm.regeneratePublicApi },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isSmallerScreen ? "" : "Regenerate"
                                ) + "\n          "
                              ),
                            ]
                          ),
                          _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.form.publicApiKey,
                                  expression: "form.publicApiKey",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.onCopy,
                                  expression: "onCopy",
                                  arg: "success",
                                },
                              ],
                              staticClass: "mt-4",
                              attrs: {
                                "icon-pack": "feather",
                                icon: "icon-clipboard",
                                title: "Copy To Clipboard",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isSmallerScreen ? "" : "Copy To Clipboard"
                                ) + "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "vx-row mt-12" }, [
                    _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                      _c("span", [_vm._v("Allow Private API Key")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col sm:w-3/4 w-full" },
                      [
                        _c("vs-switch", {
                          model: {
                            value: _vm.form.allowPrivateApiKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "allowPrivateApiKey", $$v)
                            },
                            expression: "form.allowPrivateApiKey",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.allowPrivateApiKey
                    ? _c("div", { staticClass: "vx-row mt-12" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                          _c("span", [_vm._v("Private API Key")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-3/4 w-full" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                name: "Private API Key",
                                disabled: true,
                              },
                              model: {
                                value: _vm.form.privateApiKey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "privateApiKey", $$v)
                                },
                                expression: "form.privateApiKey",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("Private API Key"))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.allowPrivateApiKey
                    ? _c(
                        "div",
                        { staticClass: "flex flex-wrap justify-end" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mt-4 mr-4",
                              attrs: {
                                type: "border",
                                color: "danger",
                                "icon-pack": "feather",
                                icon: "icon-refresh-cw",
                                title: "Regenerate private api key",
                              },
                              on: { click: _vm.regeneratePrivateApi },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isSmallerScreen ? "" : "Regenerate"
                                ) + "\n          "
                              ),
                            ]
                          ),
                          _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.form.privateApiKey,
                                  expression: "form.privateApiKey",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.onCopy,
                                  expression: "onCopy",
                                  arg: "success",
                                },
                              ],
                              staticClass: "mt-4",
                              attrs: {
                                title: "Copy To Clipboard",
                                "icon-pack": "feather",
                                icon: "icon-clipboard",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isSmallerScreen ? "" : "Copy To Clipboard"
                                ) + "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "lg:float-left mt-4" }, [
            _c("span", { staticClass: "text-sm text-danger" }, [
              _vm._v("*Required Field"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-4 mr-4",
                  attrs: { type: "border", color: "danger" },
                  on: { click: _vm.handleCancel },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "vs-button",
                { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }